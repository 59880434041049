export interface Route {
  label: string;
  to: string;
  hideInMenu?: boolean;
}

export const routes = {
  home: {
    hideInMenu: true,
    label: 'Sobre mí',
    to: '/',
  } as Route,
  whyWithMe: {
    hideInMenu: false,
    label: '¿Por qué conmigo?',
    to: '/por-que-conmigo/',
  } as Route,
  services: {
    label: 'Servicios',
    to: '/servicios/',
  } as Route,
  references: {
    label: 'Referencias',
    to: '/referencias/',
  } as Route,
  events: {
    label: 'Eventos',
    to: '/eventos',
  } as Route,
  freeResources: {
    label: 'Para ti',
    to: '/recursos-gratuitos/',
  } as Route,
  blog: {
    label: 'Blog',
    to: '/blog/',
  } as Route,
  allBlogPosts: {
    label: 'allBlogPosts',
    to: '/blog/all',
    hideInMenu: true,
  } as Route,
  contact: {
    label: 'Contacto',
    to: '/contacto/',
  } as Route,
  ebook: {
    label: 'Ebook',
    to: '/ebook/',
    hideInMenu: true,
  } as Route,
  servicesFolder: {
    coaching: {
      label: 'Coaching',
      to: '/servicios/coaching-breve/',
    },
    therapy: {
      label: 'Therapy',
      to: '/servicios/terapia-breve/',
    },
    workshops: {
      label: 'Workshops',
      to: '/servicios/talleres-de-inspiracion/',
    },
    leadership: {
      label: 'Therapy',
      to: '/servicios/habilidades-de-liderazgo-y-ventas/',
    },
  },
};
